<template>
  <div>
    <v-container style="padding-top: 4rem">
      <div style="margin-bottom:10px">
        <v-btn color="primary" @click="goHome()">
          Ga terug
        </v-btn>
      </div>
      <div class="text-center mb-8">
        <v-btn color="deep-purple" outlined @click="active = !active">
          {{ active ? "Menu verbergen" : "Menu laten zien" }}
        </v-btn>
      </div>
      <v-bottom-navigation style="margin-bottom:15px" v-if="active">
        <v-btn
          :color="activeElement === 'Pending' ? '' : ''"
          @click="activeElement = 'Pending'"
        >
          <span>Wacht op goedkeuring</span>

          <v-icon :color="activeElement === 'Pending' ? 'blue lighten-1' : ''"
            >gavel</v-icon
          >
        </v-btn>

        <v-btn
          :color="activeElement === 'Approved' ? '' : ''"
          @click="activeElement = 'Approved'"
        >
          <span>Goedgekeurd</span>

          <v-icon
            :color="activeElement === 'Approved' ? 'green lighten-1' : ''"
          >
            mdi-checkbox-marked-circle</v-icon
          >
        </v-btn>

        <v-btn
          :color="activeElement === 'Cancelled' ? '' : ''"
          @click="activeElement = 'Cancelled'"
        >
          <span>Gegeannuleerd</span>

          <v-icon :color="activeElement === 'Cancelled' ? 'red darken-1' : ''"
            >mdi-cancel</v-icon
          >
        </v-btn>
      </v-bottom-navigation>

      <v-card v-if="activeElement === 'Pending'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Boeking opzoeken"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="pendingAppointments"
          :search="search"
        >
          <template #[`item.appointment_date`]="{item}">
            {{
              item.appointment_date
                ? new Date(item.appointment_date).toLocaleDateString()
                : ""
            }}
          </template>
        </v-data-table>
      </v-card>

      <v-card v-if="activeElement === 'Approved'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="approvedAppointments"
          :search="search"
        >
          <template #[`item.appointment_date`]="{item}">
            {{
              item.appointment_date
                ? new Date(item.appointment_date).toLocaleDateString()
                : ""
            }}
          </template>
        </v-data-table>
      </v-card>

      <v-card v-if="activeElement === 'Cancelled'">
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="cancelledAppointments"
          :search="search"
        >
          <template #[`item.appointment_date`]="{item}">
            {{
              item.formatDate
                ? new Date(item.formatDate).toLocaleDateString()
                : ""
            }}
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import apiUser from "../api/apiUser";

export default {
  data() {
    return {
      active: true,
      activeElement: "Pending",
      search: "",

      pendingAppointments: [],
      approvedAppointments: [],
      cancelledAppointments: [],
      headers: [
        {
          text: "Reserveringsnummer",
          value: "id",

          sortable: false,
        },

        {
          text: "Ingepland voor",
          value: "appointment_date",
          sortable: true,
        },

        {
          text: "van",
          value: "from_time",

          sortable: false,
        },

        {
          text: "tot",
          value: "to_time",
          sortable: false,
        },

        {
          text: "Dagdeel",
          value: "per_dagdeel",
          sortable: false,
        },

        {
          text: "status",
          value: "status",
          sortable: false,
        },
      ],
    };
  },
  async mounted() {
    apiUser.getPendingAppointments().then((data) => {
      this.pendingAppointments = data;
    });

    apiUser.getApprovedAppointments().then((data) => {
      this.approvedAppointments = data;
    });

    apiUser.getCancelledAppointments().then((data) => {
      this.cancelledAppointments = data;
    });
  },

  methods: {
    goHome() {
      this.$router.push("/KlantPortaal");
    },
  },
};
</script>
