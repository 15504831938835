<template>
  <Reservations />
</template>

<script>
import Reservations from "@/components/user/Reservations";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Reservations,
  },
  metaInfo: {
    title: "Klant Dashboard | Kantoofflex",
    htmlAttrs: {
      lang: "NL",
      amp: true,
    },
  },
};
</script>